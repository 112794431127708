import { React, useRef, useState, useEffect } from "react";
import Header from "./Header";
import Footer2 from "./Footer2";
import HeaderLower from "./HeaderLower";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import secureLocalStorage from "react-secure-storage";
import swal from "sweetalert";
// import Recaptcha from "react-recaptcha";
import RegisterService from "../services/register.service";
import LogService from "../services/log.service";
import StorageService from "../services/storage.service";
import { Navigate, Redirect, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
// import PreLoader from "./PreLoader";
function RegisterForm(props) {
  const TITLE = "Levin Beautyrest Hawaii Trip Give Away | Registration Form";
  const [fields, setFields] = useState({
    fname: "",
    lname: "",
    emailid: "",
    phone: "",
    address: "",
    store_location: "",
    mattress_model: "",
    buy_mattress: "",
    sales_name_f: "",
    sales_name_l: "",
    // captcha: "",
    i_agree: "",
  });

  const [errors, setErrors] = useState({});
  const [i_agree, setI_agree] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  // const [signoutTime, setSignoutTime] = useState(180000);
  const [signoutTime, setSignoutTime] = useState(900000);
  const [value_userform, setValue_userform] = useState([]);
  const [website, setWebsite] = useState(window.location.href);
  const navigate = useNavigate();

  secureLocalStorage.removeItem("registerform");

  useEffect(() => {
    async function logCreate() {
      try {
        await LogService.createLog(website);
      } catch (e) {
        console.log(e);
      }
    }
    logCreate();
  }, [website]);

  timeout();
  function timeout() {
    setTimeout(function () {
      logout();
    }, signoutTime);
  }

  const logout = () => {
    destroy();
  };

  const destroy = () => {
    localStorage.setItem("returnpage", true);
    localStorage.removeItem("return_page", false);
    localStorage.removeItem("registerForm");
    window.location.replace("/register");
  };

  // StorageService.setRegister(false);
  localStorage.setItem("isRegister", true);

  const ref = useRef();
  useEffect(() => {
    async function test() {
      let errors = {};

      try {
        //FIXME remove this service if not used
        const response = await RegisterService.getStates();
        var value = response.data.data;
        this.setState({
          value: value,
        });
        if (response.data.status === 429 || response.data.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
        if (value.includes("redirect")) {
        } else {
          errors["questionnare"] = response.data.response;
        }
      } catch (err) {
        if (err.status === 429 || err.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
      }
    }

    test();
  }, []);

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  const onlyNumbers = (evt) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
  };

  const handleChange = (e) => {
    let { value, name } = e.target;
    if (name === "store_location" && value === "true") {
      value = "";
    }
    if (name === "mattress_model" && value === "true") {
      value = "";
    }
    if (name === "buy_mattress" && value === "true") {
      value = "";
    }

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };
  const saveErrors = (err) => {
    setErrors(err);
  };

  const handleChangecheckbox = (e, value) => {
    setI_agree(!i_agree);
  };

  // const verifyCallback = (response) => {
  //   let errors = {};
  //   if (response) {
  //     setCaptcha({
  //       captcha: true,
  //     });
  //   } else {
  //     setErrors(errors);
  //     return false;
  //   }
  // };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    // if (validate()) {
    let data = {
      fname: fields?.fname,
      lname: fields?.lname,
      email: fields?.emailid,
      phone: fields?.phone,
      address: fields?.address,
      store_location: fields?.store_location,
      mattress_model: fields?.mattress_model,
      buy_mattress: fields?.buy_mattress,
      sales_name_f: fields?.sales_name_f,
      sales_name_l: fields?.sales_name_l,
      // captcha: captcha,
      i_agree: i_agree,
    };

    try {
      try {
        LogService.createFormLog(website, "POST", data);
      } catch (e) {
        console.log(e);
      }

      const responseuserform = RegisterService.regData(data);
      responseuserform
        .then((response) => {
          LogService.apiResLog(website, JSON.stringify(response.data));

          var valueUserform = response?.data?.status;
          setValue_userform(valueUserform);

          // StorageService.setRegister(true);
          localStorage.removeItem("return_page");

          swal({
            title: "Successful!",
            text: "Form Submitted!",
            type: "success",
            icon: "success",
          }).then(() => {
            secureLocalStorage.setItem(
              "registerform",
              JSON.stringify({
                fname: fields.fname,
                lname: fields.lname,
                emailid: fields.emailid,
                phone: fields?.phone,
                address: fields?.address,
                store_location: fields.store_location,
                mattress_model: fields.mattress_model,
                buy_mattress: fields.buy_mattress,
                sales_name_f: fields.sales_name_f,
                sales_name_l: fields.sales_name_l,
                captcha: captcha,
                i_agree: i_agree,
              })
            );
            navigate("/register/registration-thankyou");
          });
          if (value_userform?.includes("redirect")) {
          }
        })
        .catch((err) => {
          LogService.apiResLog(website, JSON.stringify(err.response.data));

          let errorData = {};
          errorData["fname"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.fname &&
            err?.response?.data?.message.fname[0];
          errorData["lname"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.lname &&
            err?.response?.data?.message.lname[0];
          errorData["emailid"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.email &&
            err?.response?.data?.message.email[0];
          errorData["phone"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.phone &&
            err?.response?.data?.message.phone[0];
          errorData["address"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.address &&
            err?.response?.data?.message.address[0];
          errorData["store_location"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.store_location &&
            err?.response?.data?.message.store_location[0];
          errorData["mattress_model"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.mattress_model &&
            err?.response?.data?.message.mattress_model[0];
          errorData["buy_mattress"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.buy_mattress &&
            err?.response?.data?.message.buy_mattress[0];
          errorData["sales_name_f"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.sales_name_f &&
            err?.response?.data?.message.sales_name_f[0];
          errorData["sales_name_l"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.sales_name_l &&
            err?.response?.data?.message.sales_name_l[0];
          errorData["checkederrorcaptcha"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.captcha &&
            err?.response?.data?.message.captcha[0];
          errorData["checkederror"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.i_agree &&
            err?.response?.data?.message.i_agree[0];
          errorData["checkederrortwo"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.size &&
            err?.response?.data?.message.size[0];
          saveErrors(errorData);
          ref?.current?.focus();
        });
    } catch (err) {
      alert(err);
      if (err.response.status === 429 || err.response.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
      let errorData = {};
      errorData["fname"] = err?.response?.data?.message.fname[0];
      errorData["lname"] = err?.response?.data?.message.lname[0];
      errorData["emailid"] = err?.response?.data?.message.emailid[0];
      errorData["phone"] = err?.response?.data?.message.phone[0];
      errorData["address"] = err?.response?.data?.message.address[0];
      errorData["store_location"] =
        err?.response?.data?.message.store_location[0];
      errorData["mattress_model"] =
        err?.response?.data?.message.mattress_model[0];
      errorData["buy_mattress"] = err?.response?.data?.message.buy_mattress[0];
      errorData["sales_name_f"] = err?.response?.data?.message.sales_name_f[0];
      errorData["sales_name_l"] = err?.response?.data?.message.sales_name_l[0];
      errorData["checkederrorcaptcha"] =
        err?.response?.data?.message.captcha[0];
      errorData["checkederror"] = err?.response?.data?.message.i_agree[0];
      saveErrors(errorData);
    }
    // }
  };
  const validate = (e) => {
    let errors = {};
    let isformIsValid = true;

    if (!i_agree) {
      isformIsValid = false;
      errors["checkederror"] = "Select the checkbox";
      setErrors(errors);

      return false;
    }

    // if (!captcha) {
    //   isformIsValid = false;
    //   errors["checkederrorcaptcha"] = "Captcha not verified";
    //   saveErrors(errors);
    //   return isformIsValid;
    // }

    return isformIsValid;
  };
  // const onLoadRecaptcha = () => {};

  // if (localStorage.getItem("return_page") !== "true") {
  //   return <Navigate to="/register" />;
  // }
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        {/* <PreLoader/> */}
        <Header />
        <HeaderLower />
        <div id="main-registration-container">
          <div className="white-box form" id="font-style">
            {/* <h1>Register to receive your free reward card!</h1>
            <hr /> */}
            <form
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submituserRegistrationForm}
            >
              <div className="row">
                <div className="col-md-6">
                  <label className="formsub">
                    Customer First Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Customer First Name"
                    style={errors.fname ? { border: "1px solid red" } : null}
                    name="fname"
                    id="fname"
                    className="searchBox_deals vc-validate"
                    ref={errors.fname ? ref : null}
                    value={fields.fname || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">{errors.fname}</div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Customer Last Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="lname"
                    style={
                      errors.lname && !errors.fname
                        ? { border: "1px solid red" }
                        : null
                    }
                    placeholder="Customer Last Name"
                    id="lname"
                    className="searchBox_deals vc-validate"
                    value={fields.lname || ""}
                    ref={errors.lname && !errors.fname ? ref : null}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.lname && !errors.fname ? errors.lname : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Customer Email <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="emailid"
                    ref={
                      errors.emailid && !errors.lname && !errors.fname
                        ? ref
                        : null
                    }
                    style={
                      errors.emailid && !errors.lname && !errors.fname
                        ? { border: "1px solid red" }
                        : null
                    }
                    id="emailid"
                    placeholder="Enter Email Address"
                    value={fields.emailid || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.emailid && !errors.lname && !errors.fname
                      ? errors.emailid
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Customer Phone# <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    id="phone"
                    style={
                      errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    name="phone"
                    placeholder="Enter Phone Number"
                    value={fields.phone || ""}
                    ref={
                      errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                    onKeyUp={onlyNumbers}
                    onPaste={pasted}
                  />
                  <div className="errorMsg">
                    {errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.fname
                      ? errors.phone
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label required className="formsub">
                    Address <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Enter Address"
                    id="address"
                    className="searchBox_deals vc-validate"
                    autoFocus=""
                    value={fields.address || ""}
                    onChange={handleChange}
                    style={
                      errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname
                        ? ref
                        : null
                    }
                  />
                  <div className="errorMsg">
                    {errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.fname
                      ? errors.address
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Visited Store<span className="red_point">*</span>
                  </label>

                  <select
                    style={
                      errors.store_location &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.store_location &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname
                        ? ref
                        : null
                    }
                    name="store_location"
                    id="store_location"
                    className="select_1 dropdown"
                    onChange={handleChange}
                  >
                    <option value>Select Store</option>
                    {/* <option value="301 Fitz Henry Road Smithton, PA 15479">
                      301 Fitz Henry Road Smithton, PA 15479
                    </option> */}
                    <option value="Altoona">Altoona</option>
                    <option value="Ashley Boardman">Ashley Boardman</option>
                    <option value="Ashley Mcknight Rd">
                      Ashley Mcknight Rd
                    </option>
                    <option value="Avon">Avon</option>
                    <option value="Butler">Butler</option>
                    <option value="Cranberry">Cranberry</option>
                    <option value="Curry Hollow">Curry Hollow</option>
                    <option value="Greensburg">Greensburg</option>
                    <option value="JV Erie">JV Erie</option>
                    <option value="Mayfield">Mayfield</option>
                    <option value="Mentor">Mentor</option>
                    <option value="Middleburg">Middleburg</option>
                    <option value="Monroeville">Monroeville</option>
                    <option value="Mt Lebanon">Mt Lebanon</option>
                    <option value="Mt. Pleasant">Mt. Pleasant</option>
                    <option value="Niles">Niles</option>
                    <option value="North Canton">North Canton</option>
                    <option value="Oakwood">Oakwood</option>
                    <option value="Robinson Mattress">Robinson Mattress</option>
                    <option value="Shadyside">Shadyside</option>
                    <option value="South Hills">South Hills</option>
                    <option value="State College">State College</option>
                    <option value="Stow">Stow</option>
                    <option value="Strongsville">Strongsville</option>
                    <option value="The Point">The Point</option>
                    <option value="Washington">Washington</option>
                    <option value="Wexford">Wexford</option>
                  </select>
                  <div className="errorMsg">
                    {errors.store_location &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.fname
                      ? errors.store_location
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Which mattress model did you prefer?{" "}
                    <span className="red_point">*</span>
                  </label>

                  <select
                    style={
                      errors.mattress_model &&
                      !errors.store_location &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.mattress_model &&
                      !errors.store_location &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.name &&
                      !errors.lname &&
                      !errors.fname
                        ? ref
                        : null
                    }
                    name="mattress_model"
                    id="mattress_model"
                    className="select_1 dropdown"
                    onChange={handleChange}
                  >
                    <option value>Select Option</option>
                    <option value="Beautyrest Black B Class">
                      Beautyrest Black B Class
                    </option>
                    <option value="Beautyrest Black BX Class Hybrid">
                      Beautyrest Black BX Class Hybrid
                    </option>
                    <option value="Beautyrest Black CX Class Hybrid">
                      Beautyrest Black CX Class Hybrid
                    </option>
                    <option value="Beautyrest Black KX Class Hybrid">
                      Beautyrest Black KX Class Hybrid
                    </option>
                  </select>
                  <div className="errorMsg">
                    {errors.mattress_model &&
                    !errors.store_location &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.fname
                      ? errors.mattress_model
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Did you visit with the intention of buying a mattress today?{" "}
                    <span className="red_point">*</span>
                  </label>

                  <select
                    style={
                      errors.buy_mattress &&
                      !errors.mattress_model &&
                      !errors.store_location &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.buy_mattress &&
                      !errors.mattress_model &&
                      !errors.store_location &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.name &&
                      !errors.lname &&
                      !errors.fname
                        ? ref
                        : null
                    }
                    name="buy_mattress"
                    id="buy_mattress"
                    className="select_1 dropdown"
                    onChange={handleChange}
                  >
                    <option value>Select Option</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                  <div className="errorMsg">
                    {errors.buy_mattress &&
                    !errors.mattress_model &&
                    !errors.store_location &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.name
                      ? errors.buy_mattress
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Sales Associate First Name{" "}
                    <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Sales Associate First Name"
                    style={
                      errors.sales_name_f &&
                      !errors.buy_mattress &&
                      !errors.mattress_model &&
                      !errors.store_location &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    name="sales_name_f"
                    id="sales_name_f"
                    className="searchBox_deals vc-validate"
                    ref={
                      errors.sales_name_f &&
                      !errors.buy_mattress &&
                      !errors.mattress_model &&
                      !errors.store_location &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.name &&
                      !errors.lname &&
                      !errors.fname
                        ? ref
                        : null
                    }
                    value={fields.sales_name_f || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.sales_name_f &&
                    !errors.buy_mattress &&
                    !errors.mattress_model &&
                    !errors.store_location &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.name
                      ? errors.sales_name_f
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Sales Associate Last Name{" "}
                    <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Sales Associate Last Name"
                    style={
                      errors.sales_name_l &&
                      !errors.sales_name_f &&
                      !errors.buy_mattress &&
                      !errors.mattress_model &&
                      !errors.store_location &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    name="sales_name_l"
                    id="sales_name_l"
                    className="searchBox_deals vc-validate"
                    ref={
                      errors.sales_name_l &&
                      !errors.sales_name_f &&
                      !errors.buy_mattress &&
                      !errors.mattress_model &&
                      !errors.store_location &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.fname
                        ? ref
                        : null
                    }
                    value={fields.sales_name_l || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.sales_name_l &&
                    !errors.sales_name_f &&
                    !errors.buy_mattress &&
                    !errors.mattress_model &&
                    !errors.store_location &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.fname
                      ? errors.sales_name_l
                      : null}
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <label className="formsub mb-0">
                    <strong>Disclaimer:</strong>
                  </label>
                  <input
                    type="checkbox"
                    id="myCheck"
                    name="test"
                    className="conditions"
                    value={fields.checkbox}
                    onChange={handleChangecheckbox}
                  />
                  <span>
                    I give Levin permission to use my information for future
                    promotional initiatives <span className="red_point">*</span>
                  </span>
                  {/* <div className="errorMsg">{errors.checkederror}</div> */}
                  <div className="errorMsg">
                    {!errors.sales_name_l &&
                    !errors.sales_name_l &&
                    !errors.sales_name_f &&
                    !errors.buy_mattress &&
                    !errors.mattress_model &&
                    !errors.store_location &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.fname
                      ? errors.checkederror
                      : null}
                  </div>
                </div>
                <br />
                {/* <br />
                <br /> */}
                {/* <div className="overflow-hider">
                  <Recaptcha
                    sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                    render="explicit"
                    verifyCallback={verifyCallback}
                    onloadCallback={onLoadRecaptcha}
                  />
                </div> */}
                {/* <div className="errorMsg">{errors.checkederrorcaptcha}</div> */}
                {/* <div className="errorMsg">
                  {!errors.checkederror &&
                  !errors.sales_name_l &&
                  !errors.sales_name_f &&
                  !errors.buy_mattress &&
                  !errors.mattress_model &&
                  !errors.store_location &&
                  !errors.address &&
                  !errors.phone &&
                  !errors.emailid &&
                  !errors.lname &&
                  !errors.fname
                    ? errors.checkederrorcaptcha
                    : null}
                </div> */}
                <br />
                <div className="clearfix"></div>
              </div>

              <div class="alert alert-info">
                Limit 1 per household, while supplies last. Some restrictions
                apply, see store for detail.
              </div>
              <input
                type="submit"
                name="submit"
                id="submit"
                value="SUBMIT"
                className="sub-btn2 button Formfirst"
              />
            </form>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}
export default RegisterForm;
