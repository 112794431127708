import React from "react";
import "./HeaderLower.css";
function HeaderLower() {
  return (
    <div className="header_lower">
      {/* Rest Test a Beautyrest Black and Enter To Win “Hawai” for a Year! */}
      {/* Rest Test a Beautyrest Black and Enter To Win a trip to “Hawai”! */}
      Rest Test a Beautyrest and Enter To Win a trip to “Hawaii”!

    </div>
  );
}
export default HeaderLower;
