import React from "react";
import levinBeautyrestLogo from '../images/levinBeautyrest.png';
function Header() {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="logo">
          <img
            src={levinBeautyrestLogo}
            alt="levinBeautyrest-logo.png"
            className="img-responsive"
          />
        </div>
      </div>
    </div>
  );
}
export default Header;
